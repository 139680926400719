import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Token } from 'src/interceptor/token';
import { AuthService } from './components/service/auth.service';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { RealcollabPageComponent } from './components/landing-page/realcollab-page.component';
import { RealrecoPageComponent } from './components/landing-page/realreco-page/realreco-page.component';
import { RealtraceabilityPageComponent } from './components/landing-page/realtraceability-page/realtraceability-page.component';
import { RealwarrantyPageComponent } from './components/landing-page/realwarranty-page/realwarranty-page.component';
import { RealcollabTestUsComponent } from './components/landing-page/realcollab-test-us/realcollab-test-us.component';

@NgModule({
  declarations: [
    AppComponent,
    RealcollabPageComponent,
    TopbarComponent,
    FooterComponent,
    RealrecoPageComponent,
    RealtraceabilityPageComponent,
    RealwarrantyPageComponent,
    RealcollabTestUsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule,
    HttpClientModule

    
  ],
 
  providers: [
    // {
    // provide: HTTP_INTERCEPTORS,
    //  useClass: Token,
    //  multi: true
    // },
    AuthService,NgxSpinnerService],
  bootstrap: [AppComponent]
})
export class AppModule { }
