import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RealcollabPageComponent } from './components/landing-page/realcollab-page.component';
import { RealrecoPageComponent } from './components/landing-page/realreco-page/realreco-page.component';
import { RealtraceabilityPageComponent } from './components/landing-page/realtraceability-page/realtraceability-page.component';
import { RealwarrantyPageComponent } from './components/landing-page/realwarranty-page/realwarranty-page.component';
import { RealcollabTestUsComponent } from './components/landing-page/realcollab-test-us/realcollab-test-us.component';


const routes: Routes = [{
  path:'realcollab', component: RealcollabPageComponent
}, 
{path: 'realreco', component: RealrecoPageComponent },
{path: 'realtrace', component: RealtraceabilityPageComponent},
{path: 'realwarranty', component: RealwarrantyPageComponent},
{
  path: 'realcollab_us', component: RealcollabPageComponent
},
{ path: 'realreco_us', component: RealrecoPageComponent },
{ path: 'realltrace_us', component: RealtraceabilityPageComponent },
{ path: 'realwarranty_us', component: RealwarrantyPageComponent },
{path: 'realcollab_test_us', component: RealcollabTestUsComponent}
// {path: '', component: RealcollabPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }