import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
activated: any
  imageToggleRealcollab: boolean 
  imageToggleRealreco: boolean 
  imageToggleRealtrace: boolean 
  imageToggleRealwarrnty: boolean
  constructor() { }
  
  ngOnInit() {
    this.activated = window.location.pathname.substring(1)
    // this.activated = window.location.hash.split('/')[1]

    
    console.log("activated",this.activated)
    // this.imageToggleRealcollab = false
    // this.imageToggleRealreco = false
    // this.imageToggleRealtrace = false
    if(this.activated === 'realcollab' || this.activated === undefined || this.activated === '' || this.activated === 'realcollab_us' || this.activated === 'realcollab_test_us') {
      console.log("1st block", this.activated)
      this.imageToggleRealcollab = true
      this.imageToggleRealreco = false
      this.imageToggleRealtrace = false
      this.imageToggleRealwarrnty = false
    } else if(this.activated === 'realreco' || this.activated === 'realreco_us') {
      console.log("2nd block")

      this.imageToggleRealreco = true
      this.imageToggleRealtrace = false
      this.imageToggleRealcollab = false
      this.imageToggleRealwarrnty = false

    } else if(this.activated === 'realtrace' || this.activated === 'realltrace_us') {
    console.log("window",window.location.pathname)
    console.log("3rd block",this.activated)

    this.imageToggleRealtrace = true
    this.imageToggleRealcollab = false
    this.imageToggleRealreco = false
    this.imageToggleRealwarrnty = false

    } else if(this.activated === 'realwarranty' || this.activated === 'realwarranty_us') {
      console.log("window",window.location.pathname)
      console.log("4th block",this.activated)
  
      this.imageToggleRealtrace = false
      this.imageToggleRealcollab = false
      this.imageToggleRealreco = false
      this.imageToggleRealwarrnty = true
  
      }
  }

}
