import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: any;
  userdetails: any;
  baserUrl = environment.url+"/webhook/"
  public loginState = new Subject();

  constructor(private http: HttpClient) {
  }

  setToken(token: any) {
    console.log("tokennn", token)
    this.token = token;
    // this.loginStateChanged();
    sessionStorage.setItem("Token", token);
  }

  getToken() {
    return this.token
  }

  getTokenStorage() {
    return sessionStorage.getItem("Token")
  }

  getLoggedIn() {
    console.log("storageee", sessionStorage.getItem("Token"))
    if (sessionStorage.getItem("Token") !== undefined && sessionStorage.getItem("Token") !== null) {
      return true
    }
    else {
      return false
    }
  }

  // getLoggedUserDetails() {
  //    return JSON.parse(sessionStorage.getItem("userdetails"))
  // }

  // setLoggedUserDetails(userdetails:any) {
  //   this.userdetails =  userdetails
  //   sessionStorage.setItem("userdetails", JSON.stringify(userdetails));

  // }

  getTokenFromAPI(productName) {
    return new Promise((resolve, reject) => {
      this.http.post(this.baserUrl+'invokeTokenApi',{"URL_SLUG":productName,"resourceId":"20e940b3-4c77-4b0b-9a53-9e16a1b010a7"}).subscribe((res: any) => {
        resolve(res);
      });

    })
  }


  getResovleApi(msMarketplaceToken: any,productName: any) {
    console.log("getResovleApimsMarketplaceToken", msMarketplaceToken)
    return new Promise((resolve, reject) => {
      this.http.post(this.baserUrl+'invokeResolveApi',{
        "token": this.getTokenStorage(),
         "marketplaceId": msMarketplaceToken,
         "URL_SLUG": productName
      }).subscribe(resp => {
        console.log("ResolveAPI", resp)
        resolve(resp)
      })
    })

  }

  createSubscribe(subscriptionId: any, obj: any) {
    console.log("subscriptionId", subscriptionId, obj)
    obj['subscribeId'] = subscriptionId
    obj['token'] = this.getTokenStorage()
    return  this.http.post(this.baserUrl+'invokeSubscribeApi',obj)

  }



  loginStateChanged() {
    this.loginState.next();
  }
}
