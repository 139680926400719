import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-realcollab-page',
  templateUrl: './realcollab-page.component.html',
  styleUrls: ['./realcollab-page.component.scss']
})
export class RealcollabPageComponent implements OnInit {
  model = { firstName: null, lastName: null, email: null, organisation: null, accountantDetails: null, message: null, check: null,subscriptionName:null,offerId: null,planId: null }
  product: any;
  constructor(private http: HttpClient, private authService: AuthService, private spinner: NgxSpinnerService,private route: ActivatedRoute ) { }
  subscriptionId: any;
  resolveAPIResp: any;
  async ngOnInit() {
    AOS.init();
    this.product = window.location.pathname.substring(1)

    // http://localhost:4004/webhook/resolve

    // this.http.get('http://localhost:4004/webhook/resolve',{params:{
    //   "x-ms-correlationid": "124d9436-4e6d-4411-9b2a-6d582906c171",
    //   "x-ms-requestid": "124d9436-4e6d-4411-9b2a-6d582906c171",
    //   "x-ms-marketplace-token":"hcQwqgH4YpbON4U7JQS%2FEvNKvnIlKjp7GYUDPDva0j4s5X5TKq4TpctMumuyQDZolCCx0Gz4VfeL1N9q9dgdmLAQarcIu2f%2FVqRBoG4TNVSH%2BTIpYFbDuaOOJpPw06i704TGKWamLcuIzEvIAAw3LjSfIuW0Zc1bQmPMlRgvatJYsDuJPGoUkD63PuSQnZvz3QSfHMGaGDDYaEa22bcgz2v55ynz56RnrNxm1A1sn8mVKtu2nBN1iKB84QzuKN8p5u1yd8ZpOSrFbIqzHmDhBg%3D%3D",
    // "authorization": this.authService.getTokenStorage()
    // }}).subscribe(resp=>{
    //   console.log("Resolve", resp)
    // })
    this.spinner.show();
    console.log("snapshot", this.route.snapshot.queryParams)
    // let msMarketplaceToken = encodeURIComponent(this.route.snapshot.queryParams['token'])
    let msMarketplaceToken = this.route.snapshot.queryParams['token']

    console.log("msMarketplaceToken", msMarketplaceToken)

    console.log("this.product",this.product)
    let token = await this.authService.getTokenFromAPI(this.product)
    // console.log("token",token)

    if(token['status']['code'] === 'error') {
      // alert(token['status']['message'])
      Swal.fire({
        icon: 'error',
        text: token['status']['message'],
      })
      this.spinner.hide()
      return
    }

    if(token['primary']['message']) {
      // alert(token['primary']['message'])
      Swal.fire({
        icon: 'error',
        text: token['primary']['message'],
      })
      this.spinner.hide()
      return
    }



    this.authService.setToken(token['primary']['access_token'])

    let resolveAPI = await this.authService.getResovleApi(msMarketplaceToken,this.product)
    this.resolveAPIResp = resolveAPI
    console.log("resolveAPI",resolveAPI)
    console.log("resolveAPIResp",this.resolveAPIResp)
     
    if(resolveAPI['status']['code'] === 'error') {
      // alert(resolveAPI['status']['message'])
      Swal.fire({
        icon: 'error',
        text: resolveAPI['status']['message'],
      })
      this.spinner.hide()
      return
    }

    if(resolveAPI['primary']['message']) {
      // alert(resolveAPI['primary']['message'])
      Swal.fire({
        icon: 'error',
        text: resolveAPI['primary']['message'],
      })
      this.spinner.hide()
      return
    }

     if(resolveAPI['primary']['subscription'] !== undefined) {
    this.model.email = resolveAPI['primary']['subscription']['beneficiary']['emailId']
    this.model.firstName = resolveAPI['primary']['subscription']['beneficiary']['emailId'].split('.')[0]
    this.model.lastName = resolveAPI['primary']['subscription']['beneficiary']['emailId'].split('.')[1].split('@')[0]
    this.model.organisation = resolveAPI['primary']['subscription']['beneficiary']['emailId'].split('.')[1].split('@')[1]
    this.model.subscriptionName = resolveAPI['primary']['subscription']['name']
    this.model.offerId = resolveAPI['primary']['subscription']['offerId']
    this.model.planId = resolveAPI['primary']['subscription']['planId']
     }
    this.spinner.hide();

  }


  submit(form: NgForm, e) {
    e.preventDefault();
    console.log("formmmmmmm", this.model)
    if (form.invalid) {
      return
    }

    this.spinner.show();
    console.log("resolveAPIResp",this.resolveAPIResp['primary']['subscription']['id'])

    this.authService.createSubscribe(this.resolveAPIResp['primary']['subscription']['id'],{
      "planId": this.model.planId, 
     "test_plan": this.model.planId
     }).subscribe(resp=>{
      console.log("subscribeee",resp)

      if(resp['primary']['message']) {
       // alert(resp['primary']['message'])
       Swal.fire({
         icon: 'error',
         text: resp['primary']['message'],
       })
       this.spinner.hide()
       return
     }

      if(resp['primary'] === "") {
        this.spinner.hide()
       Swal.fire(
         'Successful!',
         'Subscribed',
       )
      } else {
       this.spinner.hide()
       Swal.fire({
         icon: 'error',
         text: 'Something went wrong!',
       })
      }
       
     })


  }
}


